<template>
  <div class="howto-standard-member-page">
    <Backbar
      :title="$t('howtoStandardPage.title')"
      @onBackClick="$router.go(-1)"
    />
    <div class="content">
      <HowToStandardTh v-if="$i18n.locale === 'th'"/>
      <HowToStandardEn v-else-if="$i18n.locale === 'en'"/>
    </div>
    <div class="is-flex justify-center action-section">
      <RoundButton @click="$router.push({ name: 'Login' })">{{$t('howtoStandardPage.action')}}</RoundButton>
    </div>
  </div>
</template>

<script>
import Backbar from '@/components/Backbar.vue'
import HowToStandardTh from '@/components/how-to-standard/HowToStandardTh.vue'
import HowToStandardEn from '@/components/how-to-standard/HowToStandardEn.vue'
import RoundButton from '@/components/base/RoundButton.vue'

export default {
  name: 'HowToStandard',
  components: {
    Backbar,
    HowToStandardTh,
    HowToStandardEn,
    RoundButton
  }
}
</script>

<style lang="scss" scoped>
.howto-standard-member-page {
  padding-bottom: 100px;
  .content {
    padding: 18px;
  }
  .action-section {
    position: fixed;
    bottom: 0;
    padding: 20px;
    width: 100%;
    background: white;
    z-index: 3;
  }
}
</style>
