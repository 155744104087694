<template>
  <div>
    <div>Please input code</div>
      <div class="is-flex justify-space-between align-items-center clipboard has-margin-top-5 has-margin-bottom-5" @click="doCopy()">
        {{code}}<b-icon icon="copy" class="has-margin-left-5"></b-icon>
      </div>
    <div>in the Registration Page in order to register for the standard membership card.</div>
    <div class="has-text-weight-medium has-margin-top-10">Note:</div>
    <div>If you register by using the above code, you will not be able to change to another type of card to gain special benefits that come with other membership cards. Please carefully check the registration code that you obtain from either participating in the hospital's event or directly sent by the hospital to you in order to gain many special benefits from the hospital.</div>
  </div>
</template>
<script>
import { standardCode } from '@/constants/config'
export default {
  data () {
    return {
      code: standardCode
    }
  },
  methods: {
    doCopy () {
      this.$copyText(this.code).then((e) => {
        this.$buefy.toast.open({
          message: 'Copy Success',
          position: 'is-bottom',
          type: 'is-success'
        })
      }, (e) => {
        this.$buefy.toast.open({
          message: e.toString(),
          position: 'is-bottom',
          type: 'is-danger'
        })
      })
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/__variables.scss';
.clipboard {
  height: 50px;
  background: $primary;
  color: white;
  padding: 0 16px;
  border-radius: 4px;
}
</style>
