<template>
  <div>
    <div>รหัสลงทะเบียนของท่านคือ</div>
    <div class="is-flex justify-space-between align-items-center clipboard has-margin-top-5 has-margin-bottom-5" @click="doCopy()">
      {{code}}<b-icon icon="copy" class="has-margin-left-5"></b-icon>
    </div>
    <div class="has-text-weight-medium has-margin-top-10">หมายเหตุ:</div>
    <div>รหัสดังกล่าวเป็นรหัสสำหรับบัตรสมาชิกประเภททั่วไป หากท่านต้องการสอบถามเพิ่มเติมสามารถติดต่อได้ที่</div>
  </div>
</template>
<script>
import { standardCode } from '@/constants/config'
export default {
  data () {
    return {
      code: standardCode
    }
  },
  methods: {
    doCopy () {
      this.$copyText(this.code).then((e) => {
        this.$buefy.toast.open({
          message: 'Copy Success',
          position: 'is-bottom',
          type: 'is-success'
        })
      }, (e) => {
        this.$buefy.toast.open({
          message: e.toString(),
          position: 'is-bottom',
          type: 'is-danger'
        })
      })
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/__variables.scss';
.clipboard {
  height: 50px;
  background: $primary;
  color: white;
  padding: 0 16px;
  border-radius: 4px;
}
</style>
